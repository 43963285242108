//File is used for API calls only
import { APIUrlList } from '../js/api_url';
// import { _sendResponse } from '../js/common-function';
import { apiCall } from '../js/common-function';

export const getPageContent = async (page_key) => {

    const response = await apiCall(APIUrlList.GETPAGECONTENT+"?slug="+page_key, "", 'GET', false);
    return response.data;
};
