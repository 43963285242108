<template>
  <b-container class="py-md-4">
    <div class="register_wrapper my-md-5 my-4 p-3'">
      <b-row class="justify-content-center">
        <b-col
          xl="10"
          lg="10"
        >
          <div
            id="aboutus"
            class="mb-5 px-3 py-2"
          />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { getPageContent } from '../../api/pageContent';
import { _sendResponse } from '../../js/common-function';

export default {
    name:'AboutUs',
    data() {
        return {
            aboutus: ""
        };
    },
    async created() {
        var response = await getPageContent('about-us');
        if  (response.status === false) {
            _sendResponse("error",response.message);
            return false;
        }
        this.aboutus = response.data.content;
        document.getElementById('aboutus').innerHTML = this.aboutus;
        document.getElementById('aboutus').style.fontWeight = "200";
    }
};
</script>